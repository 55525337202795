<template>
  <section class="faq-body">
    <faq-list :list="list1"></faq-list>
    <faq-list :list="list2"></faq-list>
  </section>
</template>

<script>
import FaqList from '@/components/item/faq-list'

export default {
  components: { FaqList },
  props: {
    list1: {
      type: Array,
      default: []
    },
    list2: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      
    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
}
</script>