
<template>
  <article id="container" class="container faq-container">
    <section class="faq-head">
      <h1>Frequently asked questions</h1>
      <p>Your Queries, Our Solutions: Dive into Our FAQ Database</p>
    </section>
    <el-tabs v-model="activeTab" @tab-click="switchTab">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.label" :name="item.name">
        <faq-pane :list1="list1" :list2="list2"></faq-pane>
      </el-tab-pane>
    </el-tabs>
  </article>
</template>

<script>
import { mapMutations } from 'vuex'
import FaqPane from '@/components/item/faq-pane'
import { AutoBodyHeight } from '@/mixins/body-height'
import { getFaqLabel, getFaqList } from '@/apis/common'

export default {
  mixins: [ AutoBodyHeight ],
  components: { FaqPane },
  props: {},
  data () {
    return {
      activeTab: '',
      tabs: [],
      list1: [],
      list2: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setLoading', 'setCommonState']),
    init() {
      this.setLoading({
        state: true,
        text: ''
      })
      getFaqLabel({}, (res) => {
        // console.log(res)
        this.setLoading({
          state: false,
          text: ''
        })
        this.tabs = res
        if (res.length > 0) {
          this.activeTab = res[0].name
          this.getList()
        }
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    },
    switchTab(tab, event) {
      // console.log(tab, event)
      this.getList()
    },
    getList() {
      this.setLoading({
        state: true,
        text: ''
      })
      getFaqList({
        type: this.activeTab
      }, (res) => {
        // console.log(res)
        var list1 = [], list2 = []
        res.forEach((item, index) => {
          // console.log(item, index)
          if (index % 2 == 0) {
            list1.push(item)
          } else {
            list2.push(item)
          }
        })
        // console.log(list1, list2)
        this.list1 = list1
        this.list2 = list2
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    }
  },
  created () {},
  mounted () {
    this.init()
  },
  beforeDestroy () {},
  destroyed () {}
}
</script>