<template>
  <el-collapse class="faq-collapse" v-model="activeName" accordion>
    <el-collapse-item v-for="(item, index) in list" :key="index" :title="item.problem" :name="'a-' + (index + 1)">
      <div class="faq-answer"  v-html="item.answer"></div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      activeName: 'a-1'
    }
  },
  computed: {},
  watch: {},
  methods: {
    
  },
  created () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
}
</script>